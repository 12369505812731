import React from 'react';
import { Card, Layout, Col } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import webManagement from '../assets/web-management.png';
import fileImg from '../assets/file.png';
import artificialIntelligence from '../assets/artificial-intelligence.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function Reinsurance() {
  return (
    <Content>
      <Row className={styles.reinsuranceRow}>
        <Col span={24}>
          <h2 className={styles.reinsurance}>
            Why <span>TAGBOT</span>?
          </h2>
          <p style={{ fontSize: '24px', fontWeight: '450' }}>
            While AWS tags and the ability to use them are easily available,
            there has been virtually no simple, cost-effective solution to
            ensure that tags are being used effectively and most importantly,
            consistently! Tagbot answers the need as the only affordable,
            simple-to-use, and effective solution to maximizing the usage and
            utility of your AWS infrastructure tags.
          </p>
        </Col>

        <div className="site-card-wrapper" style={{ width: '100%' }}>
          <Row gutter={16}>
            <Col
              span={8}
              style={{ maxWidth: 'none' }}
              className={styles.responsiveCardCenter}
            >
              <Card
                bordered={false}
                cover={
                  <img
                    className={styles.imgCard}
                    src={fileImg}
                    alt={'fileImg'}
                  />
                }
                className={styles.reinsuranceCardOne}
              >
                <p>
                  Tagbot is <span>the only simple, purpose-built tool...</span>
                </p>
                To provide the visibility and easy remediation to ensure
                effective tagging in your AWS environment.
              </Card>
            </Col>
            <Col
              span={8}
              style={{ maxWidth: 'none' }}
              className={styles.responsiveCardCenter}
            >
              <Card
                bordered={false}
                cover={
                  <img
                    className={styles.imgCard}
                    src={artificialIntelligence}
                    alt={'artificialIntelligence'}
                  />
                }
                className={styles.reinsuranceCardTwo}
              >
                <p>
                  Tagbot’s AI engine helps ensure your tags remain{' '}
                  <span>clean and consistent over time.</span>
                </p>
                Supporting your compliance, governance, cost allocation and
                infrastructure automation efforts.
              </Card>
            </Col>
            <Col
              span={8}
              style={{ maxWidth: 'none' }}
              className={styles.responsiveCardCenter}
            >
              <Card
                bordered={false}
                cover={
                  <img
                    className={styles.imgCard}
                    src={webManagement}
                    alt={'webManagement'}
                  />
                }
                className={styles.reinsuranceCardThree}
              >
                <p>
                  Tagbot’s dashboard provides a{' '}
                  <span>straightforward, easy-to-use consolidated view...</span>
                </p>
                To chart your tagging coverage and progress over time.
              </Card>
            </Col>
          </Row>
        </div>
      </Row>
    </Content>
  );
}
