import React from 'react';
import { Layout, Col } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import screenshotFeature from '../assets/feature_1.png';
import screenshotFeature2 from '../assets/feature_2.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function FeaturesPreview() {
  return (
    <Content>
      <Row
        className={styles.featuresRow}
        style={{ marginLeft: '30px', marginRight: '30px', maxWidth: '100%' }}
      >
        <div style={{ textAlign: 'center' }}>
          <Col
            style={{ paddingTop: '10px' }}
            className={styles.featuresRowFlex}
          >
            <h2 className={styles.quoteTitle} style={{ textAlign: 'center' }}>
              <span>Why Tag My Resources</span>
              <br />
              on AWS?
            </h2>
            <p style={{ fontSize: '24px', fontWeight: '450' }}>
              Although tagging may not seem like the most direct means of saving
              money, properly implemented tags can and WILL help you optimize
              expenses and improve the ROI of your cloud deployments.
            </p>
          </Col>

          <Col
            style={{ paddingTop: '10px' }}
            className={styles.featuresRowFlex}
          >
            <h2
              className={styles.quoteTitleTwo}
              style={{ textAlign: 'center' }}
            >
              The Larger Your Deployments,
              <br />
              <span>The More Painful It Can Get!</span>
            </h2>
            <p style={{ fontSize: '24px', fontWeight: '450' }}>
              It is not surprising that as cloud deployments get larger and more
              complex, companies often struggle to manage the increasing amounts
              of deployed resources that are constantly moving and evolving.
              Keeping track of these resources, often shared between multiple
              teams, can easily become cumbersome! Tags provide teams with the
              necessary visibility to monitor their AWS resources and scale
              deployments without having to worry about losing track of their
              cloud costs.
            </p>
          </Col>
        </div>

        <Col
          span={8}
          lg={12}
          style={{ paddingTop: '10px' }}
          className={styles.featuresRowFlex}
        >
          <h2 className={styles.quoteTitle}>
            <span>Your tags</span>
            <br />
            at a glance.
          </h2>
          <p style={{ fontSize: '24px', fontWeight: '450' }}>
            Get a simple dashboard overview of your AWS instances resource tags.
            Easily check, manage and update your tags. Discover opportunities to
            rationalize your tagging to support reporting and your business
            requirements.
          </p>
        </Col>
        <Col span={16} lg={12} className={styles.featuresCol}>
          <img
            className={styles.imgFeatures}
            style={{ marginLeft: 0 }}
            src={screenshotFeature}
            alt={'Smart Dashboard AWS tags'}
          />
        </Col>
      </Row>
      <div className={styles.midBg}>
        <Row
          className={styles.featuresRow}
          style={{ marginLeft: '30px', marginRight: '30px', maxWidth: '100%' }}
        >
          <Col span={16} lg={12}>
            <img
              className={styles.imgFeaturesTwo}
              style={{ marginLeft: 0 }}
              src={screenshotFeature2}
              alt={'AI/ML suggestions AWS Tagging resources Dashboard'}
            />
          </Col>
          <Col
            span={8}
            lg={12}
            style={{ paddingTop: '10px' }}
            className={styles.featuresRowFlex}
          >
            <h2 className={styles.quoteTitleTwo}>
              AI Powered smart
              <br />
              <span>tagging suggestions</span>.
            </h2>
            <p style={{ fontSize: '24px', fontWeight: '450' }}>
              Tagbot's powerful AI automatically suggests the best tags to help
              better manage your AWS resources. Save time by letting Tagbot
              recommend up to date tags to keep your AWS cloud clean.
            </p>
          </Col>
          <Col span={16} lg={12} className={styles.featuresCol}>
            <img
              className={styles.imgFeaturesThree}
              style={{ marginLeft: 0 }}
              src={screenshotFeature2}
              alt={'AI/ML suggestions AWS Tagging resources Dashboard'}
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
}
