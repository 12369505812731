import React from 'react';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Cut from '../components/Cut';
import Quote from '../components/Quote';
import FeaturesPreview from '../components/FeaturesPreview';
import Cta from '../components/Cta';
import Newsletter from '../components/Newsletter';
import Nav from '../components/Nav';
import ConsentManager from '../components/ConsentManager';
import Reinsurance from '../components/Reinsurance';

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tagbot</title>
        <meta name="author" content="Tagbot"></meta>
        <meta name="copyright" content="Tagbot"></meta>
        <meta
          property="og:description"
          content="Tagbot helps manage your AWS resources"
        ></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content="https://tagbot.cloud/"></meta>
        <meta
          property="og:image"
          content="https://tagbot.cloud/static/screenshot_main-38f818c3d0700318983c2ec11b8a9b43.png"
        ></meta>
      </Helmet>
      <Header />
      <>
        <Cut />
        <Quote />
        <FeaturesPreview />
        <Reinsurance />
        <Cta />
        <Newsletter />
        <Nav />
      </>
      <Footer />
      <ConsentManager />
    </Layout>
  );
}
