import React from 'react';
import { ConsentManager } from '@segment/consent-manager';

export default function () {
  const bannerContent = (
    <span>
      We use cookies (and other similar technologies) to collect data to improve
      your experience on our site. By using our website, you’re agreeing to the
      collection of data.
    </span>
  );
  const bannerSubContent = 'You can change your preferences at any time.';
  const preferencesDialogTitle = 'Website Data Collection Preferences';
  const preferencesDialogContent =
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.';
  const cancelDialogTitle = 'Are you sure you want to cancel?';
  const cancelDialogContent =
    'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.';

  return (
    <div
      style={{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
      }}
    >
      <ConsentManager
        writeKey="8ZQsv2BoXfnlQl1kSChaWD0aoPtlSZf4"
        bannerContent={bannerContent}
        bannerBackgroundColor="#2D3E4F"
        bannerSubContent={bannerSubContent}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
      />
    </div>
  );
}
