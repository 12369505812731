import React from 'react';
import { Layout, Col } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function Quote() {
  return (
    <Content>
      <Row>
        <Col span={24}>
          <h2 className={styles.quote}>SMARTER TAGS TODAY.</h2>
        </Col>
      </Row>
    </Content>
  );
}
